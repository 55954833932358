.navContainer {
	display: flex;
	justify-content: space-between;
	align-content: center;
	position: absolute;
	width: 100%;
}

.logoContainer {
	padding: 20px;
}

.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 30px;
	color: var(--light-font);
}

.link {
	color: var(--light-font);
	text-decoration: none;
	margin: 0 10px;
	padding: 10px;
	transition: color 0.4s ease;
	font-weight: bold;
	letter-spacing: 4px;
}

.link:hover {
	cursor: pointer;
	color: var(--red);
}

.activeLink {
	color: var(--red);
	border-bottom: var(--red) 2px solid;
}

.menuOpenBtn {
	display: none;
	color: var(--light-font);
	align-self: center;
	min-width: 25%;
}

.menuCloseBtn {
	display: none;
}

.menuOpenBtn:hover {
	cursor: pointer;
}

.menuCloseBtn:hover {
	cursor: pointer;
}

@media screen and (max-width: 768px) {
	.menuOpenBtn {
		display: block;
	}

	.menuCloseBtn {
		display: block;
	}

	.navbar {
		position: fixed;
		height: 100vh;
		min-width: 25%;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		right: 0;
		z-index: 10;
		background-color: var(--black);
		transition: all ease 0.5s;
	}

	.link {
		margin: 20px 0;
	}
}
