.wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #000000c7;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.closeBtn {
	color: var(--light-font);
	margin: 10px;
}

.container img {
	max-height: 90vh;
	max-width: 90vw;
}
