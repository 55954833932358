.wrapper {
	min-height: 100vh;
	padding: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.formContainer {
	margin: 50px 25%;
	width: 100%;
	color: var(--light-font);
	text-align: start;
}

.formContainer h3 {
	max-width: 70%;
	margin-top: 20px;
	margin-left: auto;
	padding: 20px;
	background-color: var(--red);
	text-align: center;
}

@media screen and (max-width: 1024px) {
	.wrapper {
		padding: 150px 20px;
	}

	.formContainer {
		margin: 50px 15%;
	}
}

@media screen and (max-width: 768px) {
	.formContainer {
		margin: 0;
	}

	.wrapper {
		padding: 150px 10%;
	}
}
