.button {
	border: none;
	padding: 12px 40px;
	color: var(--light-font);
	background-color: var(--red);
	align-self: center;
	justify-self: center;
	box-shadow: var(--large-shadow);
}

.button:hover {
	cursor: pointer;
	transform: scale(0.99);
	box-shadow: var(--small-shadow);
}

.buttonLabel {
	font-weight: bold;
	font-size: 14px;
}
