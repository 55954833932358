.wrapper {
	padding: 10%;
}

.container {
	margin: 100px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.section {
	display: flex;
	min-height: 400px;
	grid-auto-flow: dense;
	margin-bottom: 60px;
}

.section:nth-child(2) {
	flex-direction: row-reverse;
}

.sectionImageContainer {
	display: flex;
	flex: 0 0 50%;
}

.sectionImage {
	width: 100%;
	padding: 0 20px;
}

.sectionInfo {
	margin: 0 20px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: start;
	color: var(--light-font);
}

.sectionInfo h3 {
	margin-bottom: 10px;
}

.sectionInfo ol,
.sectionInfo ul {
	padding-left: 15px;
}

@media screen and (max-width: 1024px) {
	.wrapper {
		padding: 10% 5%;
	}
}

@media screen and (max-width: 768px) {
	.wrapper {
		padding: 50px 20px;
	}

	.section {
		flex-direction: column;
	}

	.section:nth-child(2) {
		flex-direction: column;
	}
}
