.header {
	width: 100%;
}

.headerImg {
	min-height: 100vh;
	width: 100%;
	background-size: 50%, 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.heading {
	height: inherit;
	background: inherit;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	font-size: calc(25vmin - 50px);
	text-align: start;
	margin: 0 10%;
	filter: invert(1) grayscale(1) contrast(9);
}

.subhead {
	background-color: var(--red);
	color: var(--dark-font);
	padding: 40px;
	backdrop-filter: blur(1px);
	min-width: 40%;
	align-self: flex-end;
	text-align: end;
	margin: 0 10%;
}

@media screen and (max-width: 1600px) {
	.headerImg {
		background-size: 60%, 100%;
	}
}

@media screen and (max-width: 1200px) {
	.headerImg {
		background-size: 70%, 100%;
	}
}

@media screen and (max-width: 768px) {
	.headerImg {
		background-size: 100%, 100%;
	}

	.headerImg {
		background-position: 50% 45%;
		justify-content: space-evenly;
	}
}

@media screen and (max-width: 400px) {
	.heading {
		font-size: 72px;
	}
}
