.contactForm {
	display: flex;
	flex-direction: column;
	max-width: 600px;
	margin: 30px auto 0;
}

.contactForm input {
	margin: 10px 0;
	border: none;
	border-bottom: 1px solid lightgrey;
	background-color: transparent;
	padding: 10px 0;
	color: var(--light-font);
}

.contactForm textarea {
	margin: 10px 0;
	border: none;
	border-bottom: 1px solid lightgrey;
	background-color: transparent;
	padding: 10px 0;
	color: var(--light-font);
}

.contactForm textarea:focus,
.contactForm input:focus {
	outline: none;
}
