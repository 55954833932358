@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;500;900&display=swap");

:root {
	/* Variables should be set at project initializion for use throughout app */
	--red: #c60021;
	--light-grey: #efefef;
	--dark-grey: #231f20;
	--white: #fff;
	--black: #000;

	--light-font: #fcfcfc;
	--dark-font: #0c0c0c;
	--brand-font: #c60021;
	--background-color: #231f20;
	--small-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
		rgba(0, 0, 0, 0.23) 0px 3px 6px;
	--large-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
		rgba(0, 0, 0, 0.22) 0px 10px 10px;
	--inside-shadow: rgba(0, 0, 0, 0.5) 0px 2px 10px 0px inset;
	--border-radius: 12px;
	--thin-border: 1px;
	--thick-border: 3px;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Raleway", sans-serif;
}

html {
	background-color: var(--background-color);
	color: var(--light -font);
}

.bold {
	font-weight: bold;
}

.d-none {
	display: none;
}

.d-block {
	display: block;
}

.d-flex {
	display: flex;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

/* App wide font sizes and font media queries */

h1 {
	font-size: 60px;
}

h2 {
	font-size: 50px;
}

h3 {
	font-size: 40px;
}

h4 {
	font-size: 30px;
}

h5 {
	font-size: 20px;
}

p {
	font-size: 16px;
	line-height: 1.5;
}

@media screen and (max-width: 768px) {
	h1 {
		font-size: 50px;
		text-align: center;
	}

	h2 {
		font-size: 40px;
		text-align: center;
	}

	h3 {
		font-size: 30px;
		text-align: center;
	}

	h4 {
		font-size: 22px;
		text-align: center;
	}

	h5 {
		font-size: 18px;
		text-align: center;
	}

	p {
		font-size: 16px;
		text-align: center;
	}
}

/* End font */

.App {
	min-height: 100vh;
	text-align: center;
	/* background-color: var(--background-color); */
	color: var(--primary-font);
	/* background: linear-gradient(330deg, #000000, #121010, rgba(35, 31, 32, 1)); */
	background: url(./Assets/texture.png) center top repeat;
}
