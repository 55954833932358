.wrapper {
	padding: 150px 10%;
}

.cardContainer {
	display: grid;
	gap: 60px;
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

.tileCard {
	position: relative;
	background-color: var(--red);
	box-shadow: var(--large-shadow);
	transition: all ease 0.5s;
	border-bottom: var(--red) 3px solid;
	border-radius: 4px;
}

.tileCard:hover {
	cursor: pointer;
	transform: scale(1.01);
	box-shadow: var(--black) 0px 25px 20px -15px;
}

.cardImage {
	width: 100%;
	object-fit: cover;
	/* max-height: 600px;
	min-height: 600px; */
	height: 500px;
	box-shadow: var(--small-shadow);
}

.cardInfo {
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: #00000080;
	padding: 20px;
}

.cardInfo h5 {
	color: var(--light-font);
	margin-bottom: 8px;
	font-weight: normal;
}

.modalChild h5 {
	color: white;
	background-color: #0000005e;
	width: 100%;
	transform: translateY(-100%);
	padding: 20px;
	font-weight: normal;
}

@media screen and (max-width: 600px) {
	.cardContainer {
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}

	.wrapper {
		padding: 150px 20px;
	}
}
